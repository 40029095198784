import * as Errors from 'App/lib/Errors';

class SampleCounts {
  constructor(sample, count, weightedCount, percent, weightedPercent) {
    this.sample = sample;
    this.count = count;
    this.weightedCount = weightedCount;
    this.percent = weightedPercent;
    this.weightedPercent = weightedPercent;
  }
}

class DependentVariableCounts {
  constructor(variable, code, category) {
    this.variable = variable;
    this.code = code;
    this.category = category;
    this.sampleCountMap = new Map();
  }

  get sampleCounts() {
    return [...this.sampleCountMap.values()];
  }

  sampleCount(sampleId) {
    return this.sampleCountMap.get(sampleId);
  }

  addSampleCount(sc) {
    this.sampleCountMap.set(sc.sample.id, sc);
  }

  getSampleCount(sample) {
    return this.sampleCountMap.get(sample.id);
  }
}

class IndependentVariableCounts {
  constructor(variable, code, category) {
    this.variable = variable;
    this.code = code;
    this.category = category;
    this.show = true;
    this.dependentCountMap = new Map();
  }

  get dependentCounts() {
    return [...this.dependentCountMap.values()];
  }

  get key() {
    if (this.variable) {
      return this.variable.id + "|" + this.code;
    } else {
      return "--";
    }
  }

  addDependentCount(cc) {
    this.dependentCountMap.set(cc.code, cc);
  }

  getDependentCount(code) {
    return this.dependentCountMap.get(code);
  }

  sortDependentCounts() {
    const non_na = [];
    const na = [];

    for (let dc of this.dependentCountMap.entries()) {
      if (dc[1].category && dc[1].category.is_na === true) {
        na.push(dc);
      } else {
        non_na.push(dc);
      }
    }

    this.dependentCountMap = new Map(non_na.concat(na));
  }
}

export function parseCrosstabs(variables, samples, data) {
  if (variables.length === 0 || variables.length > 2) {
    throw new Errors.SelectionError("1 or 2 variables required");
  }

  if (samples.length === 0) {
    throw new Errors.SelectionError("at least 1 sample required");
  }

  const dataModel = new Map();
  const indVar = variables[0];
  const depVar = variables[1] || null;

  let indCountFinder = null;

  if (depVar !== null) {
    indCountFinder = (indCode) => {
      let indCounts = dataModel.get(indCode);
      if (!indCounts) {
        indCounts = new IndependentVariableCounts(indVar, indCode, data[indVar.mnemonic][indCode]);
        dataModel.set(indCode, indCounts);
      }
      return indCounts;
    }
  } else {
    indCountFinder = (indCode) => {
      let indCounts = dataModel.get("blank");
      if (!indCounts) {
        indCounts = new IndependentVariableCounts(null, null, null);
        dataModel.set("blank", indCounts);
      }
      return indCounts;
    }
  }

  let sampleCountFinder = (sample, indCode, depCode) => {
    let indCounts = indCountFinder(indCode);

    let depCounts = indCounts.getDependentCount(depCode);
    if (!depCounts) {
      const v = depVar || indVar;
      depCounts = new DependentVariableCounts(v, depCode, data[v.mnemonic][depCode]);
      indCounts.addDependentCount(depCounts);
    }

    let sc = depCounts.getSampleCount(sample);
    if (!sc) {
      sc = new SampleCounts(sample, 0, 0, 0, 0);
      depCounts.addSampleCount(sc);
    }

    return sc;
  };

  for (let sample of samples) {
    const countData = data.crosstabs[sample.id];

    for (let ct of countData) {
      // ct == single crosstab
      const indCode = ct[indVar.mnemonic];
      const depCode = depVar !== null ? ct[depVar.mnemonic] : indCode;

      const count = sampleCountFinder(sample, indCode, depCode);
      count.count = ct.CASES;
      count.weightedCount = ct.WEIGHTED_CASES;
      count.percent = ct["PERCENT_" + indVar.mnemonic];
      count.weightedPercent = ct["WEIGHTED_PERCENT_" + indVar.mnemonic];
    }
  }

  const non_na = [];
  const na = [];

  for (let ic of dataModel.values()) {
    ic.sortDependentCounts();
    if (ic.category && ic.category.is_na === true) {
      na.push(ic);
    } else {
      non_na.push(ic);
    }
  }

  return non_na.concat(na);
}
