import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { recordException } from "App/lib/Analytics";

export const useApplicationConfigStore = defineStore('applicationConfig', () => {
  const baseApiUrl = ref(null);
  const baseIpumsUrl = ref(null);
  const isIOS = ref(false);

  const alerts = ref([]);
  const errorMessage = ref(null);
  const errorObj = ref(null);
  const hasError = ref(false);
  const loadingCount = ref(0);

  const isLoading = computed(() => loadingCount.value > 0);

  const visibleAlerts = computed(() => {
    alerts.value.filter(a => a.visible);
  });

  function addAlert(alert) {
    alerts.value.push(alert);
  }

  function removeAlert(alertId) {
    let a = alerts.value.find(a => a.id === alertId);
    if (a) {
      a.visible = false;
    }
  }

  function setError(error) {
    if (console)
      console.log(error);

    hasError.value = true;
    errorObj.value = error;

    if (error && error.message) {
      errorMessage.value = error.message;
    } else if (error) {
      errorMessage.value = error.toString();
    } else {
      errorMessage.value = "Unknown Error";
    }

    recordException(errorMessage.value, true);
  }

  function clearError() {
    errorObj.value = null;
    errorMessage.value = null;
    hasError.value = false;
  }

  function setLoading(val) {
    if (val === true) {
      loadingCount.value = loadingCount.value + 1;
    } else {
      loadingCount.value = loadingCount.value - 1;
    }
  }

  return {
    baseApiUrl,
    baseIpumsUrl,
    isIOS,
    alerts,
    errorMessage,
    errorObj,
    hasError,
    loadingCount,

    isLoading,
    visibleAlerts,

    addAlert,
    removeAlert,
    clearError,
    setError,
    setLoading
  };
});