import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import * as Errors from 'App/lib/Errors';
import SessionSerializer from "App/lib/SessionSerializer";
import { recordTabulationMenuAction } from "App/lib/Analytics";
import { useTabulationStore } from "./tabulation";
import intersection from "lodash/intersection";
import api from "../lib/Api";
import Subpopulation from "../lib/Subpopulation";

export const useSelectionStore = defineStore('selection', () => {
  const allPlacesPlace = {id: -1, name: 'Entire USA'};

  const availableMeasures = [
    {value: 'percent', name: 'Percent', shortName: 'Percent'},
    {value: 'weighted-count', name: 'Weighted-count', shortName: 'W-Count'},
    {value: 'count', name: 'Count', shortName: 'Count'},
  ];

  const selectedVariables = ref([]);
  const selectedSamples = ref([]);
  const selectedPlace = ref(allPlacesPlace);
  const selectedSubpopulations = ref([]);
  const selectionErrors = ref([]);

  const hasPlaces = ref(false);
  const allPlaces = ref([allPlacesPlace]);
  const allSamples = ref(null);

  const dataDisplayOptions = ref({
    showGraphs: true,
    availableMeasures: availableMeasures,
    measure: availableMeasures[0]
  });

  const canSelectVariable = computed(() => {
    return selectedVariables.value.length < 2;
  });

  const canSelectSample = computed(() => {
    return selectedSamples.value.length < 2;
  });

  const serializedSession = computed(() => {
    const store = {
      selectedVariables: selectedVariables.value,
      selectedSamples: selectedSamples.value,
      selectedSubpopulations: selectedSubpopulations.value,
      selectedPlace: selectedPlace.value,
      dataDisplayOptions: dataDisplayOptions.value
    };

    return SessionSerializer.fromStore(store).serialize();
  });

  const subpopulationVariables = computed(() => {
    return selectedSubpopulations.value.map(s => s.variable);
  });

  const allVariableSampleIds = computed(() => new Set(intersection(...selectedVariables.value.map(v => v.sample_ids))));

  function isVariableSelected(v) {
    return selectedVariables.value.findIndex(sv => sv.id === v.id) >= 0;
  }

  function isSampleSelected(s) {
    return (s === null && selectedSamples.value.length === 0 || (selectedSamples.value.findIndex(ss => ss.id === s.id) >= 0));
  }

  function subpopulationForVariable(v) {
    return selectedSubpopulations.value.find(sp => sp.variable.id === v.id) || null;
  }

  function isVariableSubpopulation(v) {
    return subpopulationForVariable(v) !== null;
  }

  function setShowGraphOption(data) {
    dataDisplayOptions.value.showGraphs = data;
    recordTabulationMenuAction("graph");
  }

  function setMeasureOption(data) {
    dataDisplayOptions.value.measure = data;
    recordTabulationMenuAction("measure");
  }

  function swapVariables() {
    selectedVariables.value.reverse();
    const tabulationStore = useTabulationStore();
    tabulationStore.resetCrossTabData();
    recordTabulationMenuAction("flip");
  }

  function isVariableAvailable(v) {
    return intersection(v.sample_ids, selectedSamples.value.map(s => s.id)).length === selectedSamples.value.length;
  }

  function isSampleAvailable(s) {
    if (selectedVariables.value.length === 0) {
      return true;
    } else if (s === null) {
      return false;
    } else {
      return allVariableSampleIds.value.has(s.id);
    }
  }

  async function getSamples() {
    if (allSamples.value === null) {
      allSamples.value = await api.getSamples();
    }

    return allSamples.value;
  }

  async function getPlaces() {
    if (hasPlaces.value === false) {
      const places = await api.getPlaces();
      allPlaces.value = allPlaces.value.concat(places);
      hasPlaces.value = true;
    }

    return allPlaces.value;
  }

  function validateSelection() {
    const errors = [];

    if (selectedVariables.value.length === 0) {
      errors.push("No Variables selected");
    }

    if (selectedSamples.value.length === 0) {
      errors.push("No Times selected");
    }

    selectedVariables.value.concat(subpopulationVariables.value).forEach(v => {
      if (!isVariableAvailable(v)) {
        errors.push(`Variable ${v.mnemonic} is not available in all selected Times`);
      }
    });

    selectionErrors.value = errors;
  }

  async function selectVariable(variable) {
    if (!canSelectVariable.value) {
      throw new Errors.SelectionError("Cannot select more than 2 variables");
    } else if (isVariableSelected(variable)) {
      throw new Errors.SelectionError("Cannot select the same variable again");
    } else {
      const tabulationStore = useTabulationStore();
      tabulationStore.resetCrossTabData();
      selectedVariables.value.push(variable);
      validateSelection();
    }
  }

  async function removeVariable(variable) {
    if (isVariableSelected(variable)) {
      selectedVariables.value = selectedVariables.value.filter(v => v.id !== variable.id);
      const tabulationStore = useTabulationStore();
      tabulationStore.resetCrossTabData();
      validateSelection();
    } else {
      throw new Errors.SelectionError("The variable [" + variable ? variable.id : "UNKNOWN" + "] is not selected");
    }
  }

  async function selectSample(sample) {
    if (!canSelectSample.value) {
      throw new Errors.SelectionError("Cannot select more than 2 samples");
    } else if (isSampleSelected(sample)) {
      throw new Errors.SelectionError("Cannot select the same sample again")
    } else {
      selectedSamples.value.push(sample);
      selectedSamples.value.sort((a, b) => a.year - b.year);
      const tabulationStore = useTabulationStore();
      tabulationStore.resetCrossTabData();
      validateSelection();
    }
  }

  async function removeSample(sample) {
    if (isSampleSelected(sample)) {
      selectedSamples.value = selectedSamples.value.filter(s => s.id !== sample.id);
      const tabulationStore = useTabulationStore();
      tabulationStore.resetCrossTabData();
      validateSelection();
    } else {
      throw new Errors.SelectionError("The sample [" + sample ? sample.id : "UNKNOWN" + "] is not selected")
    }
  }

  async function selectPlace(place) {
    selectedPlace.value = place || allPlacesPlace;
    const tabulationStore = useTabulationStore();
    tabulationStore.resetCrossTabData();
    validateSelection();
  }

  async function removePlace() {
    return selectPlace(allPlacesPlace);
  }

  async function removeSubpopulation(subpop) {
    let idx = selectedSubpopulations.value.findIndex(s => s.variable.id === subpop.variable.id);
    if (idx >= 0) {
      selectedSubpopulations.value.splice(idx, 1);
    }
    const tabulationStore = useTabulationStore();
    tabulationStore.resetCrossTabData();
    validateSelection();
  }

  async function selectSubpopulation(subpop) {
    let idx = selectedSubpopulations.value.findIndex(s => s.variable.id === subpop.variable.id);
    if (idx >= 0) {
      selectedSubpopulations.value.splice(idx, 1, subpop);
    } else {
      selectedSubpopulations.value.push(subpop);
    }
    const tabulationStore = useTabulationStore();
    tabulationStore.resetCrossTabData();
    validateSelection();
  }

  function clearSelection() {
    selectedVariables.value = [];
    selectedSamples.value = [];
    selectedSubpopulations.value = [];
    selectedPlace.value = allPlacesPlace;
    const tabulationStore = useTabulationStore();
    tabulationStore.resetCrossTabData();
    validateSelection();
  }

  async function restoreSession(data) {
    const sessionSerializer = SessionSerializer.fromSerializedData(data);
    clearSelection();
    setShowGraphOption(sessionSerializer.showGraphs);
    setMeasureOption(availableMeasures.find(av => sessionSerializer.measure === av.value));

    for (const varId of sessionSerializer.variableIds) {
      const variable = await api.variable(varId);
      await selectVariable(variable);
    }

    for (const sampleId of sessionSerializer.sampleIds) {
      const sample = await api.sample(sampleId);
      await selectSample(sample);
    }

    for (const subpopId of sessionSerializer.subpopulationIds) {
      const data = await api.getVariableWithCategories(subpopId.variableId);
      let subp = new Subpopulation(data.variable, data.categories);
      subpopId.categoryIds.forEach(cid => subp.addCategory(cid));
      await selectSubpopulation(subp);
    }

    if (sessionSerializer.placeId !== null) {
      const places = await getPlaces();
      await selectPlace(places.find(p => p.id === sessionSerializer.placeId));
    }
  }

  return {
    allPlaces,
    hasPlaces,
    allSamples,
    dataDisplayOptions,
    selectedVariables,
    selectedSamples,
    selectedPlace,
    selectedSubpopulations,
    selectionErrors,

    canSelectVariable,
    canSelectSample,
    serializedSession,
    subpopulationVariables,

    restoreSession,
    getPlaces,
    getSamples,
    isVariableAvailable,
    isVariableSelected,
    isVariableSubpopulation,
    isSampleAvailable,
    isSampleSelected,
    removeVariable,
    selectVariable,
    selectSample,
    removeSample,
    selectPlace,
    removePlace,
    setShowGraphOption,
    setMeasureOption,
    swapVariables,
    subpopulationForVariable,
    selectSubpopulation,
    removeSubpopulation
  }
});
