
function isGtagAvailable() {
  return !!window.gtag && !!window.GA_TRACKING_ID;
}

export function recordRouteChange(path) {
  if (!isGtagAvailable())
    return;

  window.gtag("config", window.GA_TRACKING_ID, {"page_path": path});
}

export function recordTabulationMenuAction(name) {
  if (!isGtagAvailable())
    return;

  let action = name;

  window.gtag("event", action, {
    event_category: "tabulation_menu"
  });
}

export function recordTabulation(variables, samples, place, subpopulations) {
  if (!isGtagAvailable())
    return;

  let action = `${variables.length}-Var ${samples.length}-Sample`;
  if (place && place.id >= 0) {
    action = action + " Place";
  }

  if (subpopulations && subpopulations.length > 0) {
    action = action + " Subpop";
  }

  window.gtag("event", action, {
    event_category: "tabulation",
  });
}

export function recordException(errorMessage, isFatal) {
  if (!isGtagAvailable())
    return;

  window.gtag("event", "exception", {
    "description": errorMessage,
    "fatal": !!isFatal
  });

}

