
export function ApiError(message, response) {
  this.message = (message || "Unknown API Error Occurred");
  this.response = response;
}
ApiError.prototype = Object.assign(new Error(), {
  name: "ApiError",

  responseCode: function() {
    if (this.response) {
      return this.response.status;
    } else {
      return null;
    }
  }
});

export function ApiServerError(message, response) {
  this.message = (message || "Unknown API Server Error Occurred");
  this.response = response;
}
ApiServerError.prototype = Object.assign(new ApiError(), {
  name: "ApiServerError"
});

export function ApiNotFoundError(message, response) {
  this.message = (message || "Unknown API Server Error Occurred");
  this.response = response;
}
ApiNotFoundError.prototype = Object.assign(new ApiError(), {
  name: "ApiNotFoundError"
});

export function SelectionError(message) {
  this.message = (message || "Invalid Selection");
}
SelectionError.prototype = Object.assign(new Error(), {
  name: "SelectionError"
});

export function SessionSerializationError(message) {
  this.message = (message || 'Error while serializing or deserializing session data');
}
SessionSerializationError.prototype = Object.assign(new Error(), {
  name: "SessionSerializationError"
});


export function onlyFor(errorType, handler) {
  return (err) => {
    if (err instanceof errorType) {
      return handler(err);
    } else {
      return Promise.reject(err);
    }
  };
}

