import { defineStore } from "pinia";
import { computed, ref } from "vue";
import api from "App/lib/Api";
import { recordTabulation, recordTabulationMenuAction } from "App/lib/Analytics";
import { useSelectionStore } from "./selection";
import { parseCrosstabs } from "App/lib/CrossTabData";

export const useTabulationStore = defineStore('tabulation', () => {
  const crossTabData = ref(null);

  function resetCrossTabData() {
    crossTabData.value = null;
  }

  function setCrossTabCollapse(isCollapsed) {
    if (crossTabData.value === null) { return; }
    for (let c of crossTabData.value) {
      c.show = !isCollapsed;
    }
    recordTabulationMenuAction("collapse");
  }

  function setCrossTabItemCollapse(payload) {
    if (crossTabData.value === null) { return; }
    const cti = crossTabData.value.find(c => c.code === payload.code);
    if (cti) {
      cti.show = !payload.isCollapsed;
    }
  }

  function getCrossTabData() {
    const selectionStore = useSelectionStore();
    if (selectionStore.selectedVariables.length > 0 && selectionStore.selectionErrors.length === 0) {
      return api.crossTabs(selectionStore.selectedVariables.map(v => v.id), selectionStore.selectedSamples.map(s => s.id), selectionStore.selectedPlace, selectionStore.selectedSubpopulations)
        .then(data => {
          recordTabulation(selectionStore.selectedVariables, selectionStore.selectedSamples, selectionStore.selectedPlace, selectionStore.selectedSubpopulations);
          crossTabData.value = parseCrosstabs(selectionStore.selectedVariables, selectionStore.selectedSamples, data);
        });
    } else {
      // noop
      return Promise.resolve();
    }
  }

  return {
    crossTabData,

    getCrossTabData,
    resetCrossTabData,
    setCrossTabCollapse,
    setCrossTabItemCollapse
  }
});
